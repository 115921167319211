import {useMemo, useEffect} from 'react';
import {DateTime} from 'luxon';

import {ContentStore, IContentStore} from '../../stores/content';
import c from '../../lib/connectors';
import {Macro} from '../../stores/types';

const useContent = (): {
  autoApply?: Macro;
  autoReply?: IContentStore['data']['autoReply'];
} => {
  const autoReply = ContentStore.useState((s) => s.data.autoReply);

  const {suggestedIds, autoApplyIds, macros} = ContentStore.useState((s) => ({
    suggestedIds: s.data.suggestedMacroIds ?? [],
    autoApplyIds: s.data.autoApplyMacroIds ?? [],
    macros: s.macros,
  }));

  const autoApply: Macro | undefined = useMemo(() => {
    if (suggestedIds.length === 1 && autoApplyIds.includes(suggestedIds[0])) {
      return macros.find(({id}) => id === suggestedIds[0]);
    }
  }, [suggestedIds, autoApplyIds, macros]);

  const scheduledAutoReply = useMemo(() => {
    if (!autoReply) {
      return;
    }
    if (DateTime.fromISO(autoReply.replyAt).isValid) {
      return autoReply;
    }
  }, [autoReply]);

  useEffect(() => {
    if (autoApply) {
      c().interactions.applyPreparedMacro({macro: autoApply});
    }
  }, [autoApply]);

  return {
    autoApply: autoApply,
    autoReply: scheduledAutoReply,
  };
};

export default useContent;
