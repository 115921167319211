import firebase from 'firebase/app';

import {AppStore, ContentStore} from '../stores';
import {Status} from '../stores/app';

import ZAFClient from './connectors/zendesk/utils/zaf-client';
import {getConnector} from './connectors';
import {getOperatorAction} from './operatorAction';
import {initAnalytics} from './analytics';
import {initSetting} from './settings';

export const init = async () => {
  // if Zendesk
  if (ZAFClient) {
    AppStore.update((s) => {
      s.connector = 'zendesk';
    });
  }

  // * Init content and settings
  const content = await getConnector().init.initContent();
  initSetting('defaultTab');

  // * Init user properties
  const userProperties = await getConnector().analytics.getUserProperties();
  initAnalytics(userProperties);

  // * No content ? Stop here
  if (!(content?.data && content?.messages && content?.macros)) {
    console.warn('Initilization incomplete');
    firebase.analytics().logEvent('incomplete_init');
    return;
  }

  // * OnSave, send operatorAction
  getConnector().init.onSave(async () => {
    const action = await getOperatorAction();
    if (action) {
      firebase.analytics().logEvent('operator_action', {action});
      await getConnector().requests.operatorAction({action});
    }
  });

  ContentStore.update((s) => {
    s.data = content.data;
    s.messages = content.messages;
    s.macros = content.macros;
  });

  AppStore.update((s) => {
    s.status = Status.DISPLAY_CONTENT;
  });

  firebase.analytics().logEvent('display_content');
};
