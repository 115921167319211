import React, {useEffect} from 'react';

import {ContentStore} from './stores';
import {AppStore} from './stores/app';

const Debugger = () => {
  useEffect(() => {
    const unsubscribeFromContentStore = ContentStore.subscribe(
      (s) => s,
      (watched, _allState, prevWatched) =>
        log({title: 'Content State', prevWatched, watched})
    );

    return () => {
      console.info('Unsubscribe from ContentStore');
      unsubscribeFromContentStore();
    };
  }, []);

  useEffect(() => {
    const unsubscribeFromAppStore = AppStore.subscribe(
      (s) => s,
      (watched, _allState, prevWatched) =>
        log({title: 'App State', prevWatched, watched})
    );

    return () => {
      console.info('Unsubscribe from AppStore');
      unsubscribeFromAppStore();
    };
  }, []);

  return <div></div>;
};

export default Debugger;

function log({
  title,
  prevWatched,
  watched,
}: {
  title: string;
  prevWatched: any;
  watched: any;
}) {
  console.info(`%c${title}`, 'color: #bada55');
  console.info('🔴 Old state', prevWatched);
  console.info('🟢 New state', watched);
}
