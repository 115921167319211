import {Store} from 'pullstate';
import {Macro, Action} from './types';

type Intent = {
  locale: string;
  raw: string;
};

type ActionHistory = {
  id: string;
  label: string;
  message: string;
  statusCode: number;
  succeededAt: string;
};

type Sabotage = {
  macroId: string;
  scheduledAt: string;
};

type AutoReply = {
  id: string;
  macroId: string;
  replyAt: string; // ISO Date
  scheduledAt: string; // ISO Date
  sendingTimestamp: string; // ISO Date
};

export type Filter = {
  id: string;
  suggest: boolean;
  reason: string;
  log: string[];
  executionTimeMs: number;
};

export interface IContentStore {
  messages: {body: string}[];
  data: {
    suggestedMacroIds?: string[];
    suggestedActions: Action[];
    actionsHistory: ActionHistory[];
    autoApplyMacroIds?: string[];
    metaData?: {
      [key: string]: string;
    };
    intent?: Intent;
    loggerFlags?: {
      applicationEnabled: boolean;
      macroSupportEnabled: boolean;
    };
    sabotage?: Sabotage;
    autoReply?: AutoReply;
    isFreemium: boolean;
    displayConfig: {
      showIntent: boolean;
    };
    filtersResult?: Filter[];
  };
  macroApplied?: string;
  macros: Macro[];
}

export const ContentStore = new Store<IContentStore>({
  messages: [],
  data: {
    suggestedActions: [],
    actionsHistory: [],
    autoApplyMacroIds: [],
    metaData: undefined,
    intent: undefined,
    loggerFlags: undefined,
    autoReply: undefined,
    isFreemium: false,
    displayConfig: {
      showIntent: true,
    },
    filtersResult: [],
  },
  macroApplied: undefined,
  macros: [],
});
