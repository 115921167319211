import i18n from '../../../i18n';

type LegacyAlert =
  | {
      color: 'red' | 'yellow' | 'green';
      title: string;
      description: string;
    }
  | undefined;

export const legacyAlertHandler = (messages: {body: string}[]): LegacyAlert => {
  const body = messages[0]?.body ?? '';

  if (messages?.length !== 1) {
    return;
  }

  // * Error with backoffice
  if (body.includes('veuillez vous rapprocher de votre service informatique')) {
    return {
      color: 'red',
      title: i18n.t('Customer data'),
      description: i18n.t(
        'We were unable to contact your API.\nPlease contact your IT department.'
      ),
    };
  }

  // * No backoffice
  if (body.includes('Configuration Back Office')) {
    return {
      color: 'yellow',
      title: i18n.t('BackOffice configuration'),
      description: i18n.t(
        'Your Back Office access has not yet been configured'
      ),
    };
  }

  // * RGPD
  if (body.includes('confidentialité et de protection des données')) {
    return {
      color: 'yellow',
      title: i18n.t('Privacy restrictions'),
      description: i18n.t(
        'We can no longer display data for this message due to privacy restrictions'
      ),
    };
  }

  // * Analysis
  if (body.includes('Analyse en cours')) {
    return {
      color: 'green',
      title: i18n.t('Ticket analysis'),
      description: i18n.t('This ticket is currently being analyzed.'),
    };
  }

  // * Language unavailable
  if (body.includes("La langue de ce message n'est pas supportée")) {
    return {
      color: 'yellow',
      title: i18n.t('Language not supported'),
      description: i18n.t(
        'The detected language of this message is not supported by the Assisstant.\nLanguage:',
        {
          language:
            body
              ?.match(/\(.*\)/g)?.[0]
              ?.replace('(', '')
              ?.replace(')', '')
              ?.toUpperCase() ?? '-',
        }
      ),
    };
  }

  // * Handle any alert
  if (body.includes('alert alert-')) {
    const doc = new DOMParser().parseFromString(body, 'text/html');
    return {
      color: (body ?? '').includes('alert-danger') ? 'red' : 'yellow',
      title: doc.getElementsByTagName('strong')[0].innerText,
      description: doc.getElementsByTagName('p')[0].innerText,
    };
  }

  return;
};
