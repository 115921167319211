import React from 'react';

import {SettingsButton} from '../../Components';
// import Actions from './Actions';
import Tabs from './Tabs';
import Macros from './Macros';
import Intent from './Intent';
import AutoReply from './Auto-Reply';
import AutoApply from './Auto-Apply';

import useContent from './useContent';
import Sabotage from './Sabotage';

const Content = () => {
  const {autoReply, autoApply} = useContent();

  return (
    <div className="flex flex-col h-full relative">
      <SettingsButton />
      <Sabotage />
      <AutoReply autoReply={autoReply} />
      {!autoReply && (
        <>
          <AutoApply autoApply={autoApply} />
          <Macros />
          <Tabs />
        </>
      )}
      {/* NOT SUPPORTED YET {!autoReply && <Actions />} */}
      <Intent />
    </div>
  );
};

export default Content;
