import React, {useMemo} from 'react';

import {Filter} from '../../../stores/content';
import {LogicLogs, parseLogs} from '../../../Components/Logic-logs';

const Logic = ({filtersResult = []}: {filtersResult?: Filter[]}) => {
  const logs = useMemo(
    () => ({
      suggested: parseLogs(filtersResult.filter(({suggest}) => suggest)),
      notSuggested: parseLogs(filtersResult.filter(({suggest}) => !suggest)),
    }),
    [filtersResult]
  );

  return (
    <>
      <LogicLogs logs={logs.suggested} />

      {!!logs.suggested.length && !!logs.notSuggested.length && (
        <hr className="my-2" />
      )}

      <LogicLogs
        logs={logs.notSuggested.filter(
          (label) => !logs.suggested.includes(label)
        )}
      />
    </>
  );
};

export default Logic;
