import ZAFClient from '../zaf-client';

const setBrand = async (ticket: any = {}): Promise<void> => {
  const brand = ticket?.brand_id;

  if (!brand) {
    return;
  }

  try {
    ZAFClient.set('ticket.brand', brand);
  } catch (error) {
    console.error('Error setting brand', error);
  }
};

export default setBrand;
