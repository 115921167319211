import React from 'react';
import {useTranslation} from 'react-i18next';

import {AppStore} from '../stores/app';
import {SettingsButton, Tabs} from '../Components';
import {setSetting} from '../lib/settings';

const Settings = () => {
  const {t} = useTranslation();
  const tab = AppStore.useState((s) => s.settings.defaultTab);

  return (
    <div className="flex flex-col h-full relative">
      <SettingsButton />
      <div className="pb-4">
        <span className="font-medium text-gray-700 text-lg "></span>
        {t('Settings')}
      </div>
      <div>
        <label className="text-sm font-medium text-gray-700">
          {t('Select the default tab')}
        </label>
      </div>

      <Tabs
        tabs={{logic: true, user: true, data: true}}
        tab={tab}
        setTab={(value) => setSetting({key: 'defaultTab', value})}
        className="opacity-75"
      />
    </div>
  );
};

export default Settings;
