import React, {useCallback, useEffect, useMemo} from 'react';
import {User, Database, Bell} from 'react-feather';
import firebase from 'firebase/app';

import {DefaultTab} from '../stores/app';
import styled from 'styled-components';

export const Tabs = ({
  tab,
  setTab,
  tabs,
  className = '',
}: {
  tab: DefaultTab;
  setTab: (x: DefaultTab) => void;
  tabs: {logic: boolean; user: boolean; data: boolean};
  className?: string;
}) => {
  const tabKeys = useMemo(() => Object.keys(tabs), [tabs]);

  // * Fallback on enabled tabs if selected tabs isn't available
  useEffect(() => {
    if (!tabs[tab]) {
      // @ts-ignore
      setTab(tabKeys.find((key) => !!tabs[key]));
    }
  }, [tabs, setTab, tab, tabKeys]);

  if (tabKeys.length <= 1) {
    return null;
  }

  return (
    <nav className={`bg-white mb-4 ${className}`}>
      <div className="flex items-baseline justify-center text-sm  mx-10 border-b ease-in-out">
        {tabs.logic && (
          <div className="relative">
            <div className="norification w-2 h-2 bg-red-500 rounded-full absolute right-4 top-1 animate-ping" />
            <Tab name="logic" tab={tab} setTab={setTab} Icon={Bell} />
          </div>
        )}
        {tabs.user && <Tab name="user" tab={tab} setTab={setTab} Icon={User} />}
        {tabs.data && (
          <Tab name="data" tab={tab} setTab={setTab} Icon={Database} />
        )}
      </div>
    </nav>
  );
};

const Tab = ({
  tab,
  name,
  Icon,
  setTab,
}: {
  tab: DefaultTab;
  name: DefaultTab;
  Icon: React.ElementType;
  setTab: (x: DefaultTab) => void;
}) => {
  const handleClick = useCallback(() => {
    firebase.analytics().logEvent('screen_view', {
      app_name: 'client_app',
      screen_name: `tab_${name}`,
    });
    setTab(name);
  }, [name, setTab]);
  return (
    <button
      onClick={handleClick}
      className={`mx-2 px-3 pb-2 pt-1 h-12 flex items-center focus:outline-none transition duration-150 ${
        name === tab
          ? 'border-blue-500 text-blue-500 border-b-2'
          : 'text-gray-700 hover:text-gray-600'
      }`}
    >
      <Icon size={18} />
    </button>
  );
};
