import React from 'react';
import MacroButton from './Macro-Button';
import Search from './Search';
import useMacros from './useMacros';

const Macros = () => {
  const {t, macrosByCategory} = useMacros();

  return (
    <div>
      <div className="font-medium text-gray-700 text-lg">{t('Macros')}</div>
      <Search />

      {Object.keys(macrosByCategory).map((key, index) => (
        <div key={index} className="mb-2">
          {key && (
            <h3 className="font-medium text-gray-600 text-sm mt-1">{key}</h3>
          )}
          <div className="space-y-1 pl-1 text-sm">
            {macrosByCategory[key].map((macro) => (
              <MacroButton macro={macro} key={macro.id} suggested />
            ))}
          </div>
        </div>
      ))}

      <hr className="my-4" />
    </div>
  );
};

export default Macros;
