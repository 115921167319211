import {ContentStore} from '../../../../stores';
import {Connector} from '../..';
import ZAFClient from '../utils/zaf-client';

import {rq, phoneSupport} from './utils';

export const display: Connector['requests']['display'] = async () => {
  const [ticket, zendeskUser] = await Promise.all([
    ZAFClient.get('ticket').then((r: any) => r.ticket),
    ZAFClient.get('currentUser').then((r: any) => r.currentUser),
  ]);

  const data = {
    message: 'display_zendesk_app',
    context: {
      ticket: phoneSupport({ticket}),
      currentUser: zendeskUser,
    },
    conversation: {
      id: ticket?.id,
      type: 'ticket',
    },
  };
  return rq({path: '/bot/api/v2/', data});
};

export const feedback: Connector['requests']['feedback'] = async () => {
  const [ticket, zendeskUser] = await Promise.all([
    ZAFClient.get('ticket').then((r: any) => r.ticket),
    ZAFClient.get('currentUser').then((r: any) => r.currentUser),
  ]);

  const externalId = String(ticket.id);
  const operatorEmail = String(zendeskUser.email);

  if (!externalId || !operatorEmail) {
    throw new Error('Missing parameters');
  }

  const data = {externalId, operator: operatorEmail};

  return rq({path: '/feedbacks', data});
};

export const analytics: Connector['requests']['analytics'] = async ({
  eventValue,
  eventId,
}) => {
  const contentStore = ContentStore.getRawState();

  const [ticket, zendeskUser] = await Promise.all([
    ZAFClient.get('ticket').then((r: any) => r.ticket),
    ZAFClient.get('currentUser').then((r: any) => r.currentUser),
  ]);

  const data = {
    conversationType: 'ticket',
    conversationId: String(ticket.id),
    operator: zendeskUser.email,
    intent: contentStore.data.intent?.raw,
    eventId,
    eventValue: eventValue.substring(0, 120),
  };

  return rq({path: '/analytics', data});
};

export const operatorAction: Connector['requests']['operatorAction'] = async ({
  action,
}) => {
  const ticket = await ZAFClient.get('ticket').then((r: any) => r.ticket);

  const data = {
    variables: {ticketId: String(ticket.id), action},
    query: `mutation AddOperationAction(
        $ticketId: String!
        $action: OperatorAction!
      ) {
      addOperatorAction(input: {
        zendeskTicketReference: {
          ticketId: $ticketId
        }
        action: $action
      })
    }`,
  };

  return rq({path: '/graphql', data});
};
