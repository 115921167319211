import {Connector} from '..';
import ZAFClient from './utils/zaf-client';

export const getUserProperties: Connector['analytics']['getUserProperties'] = async () => {
  const user = await ZAFClient.get('currentUser').then(
    (r: any) => r.currentUser
  );

  return {
    id: `zdk-${user.id}`,
    role: user.role,
    locale: user.locale,
    name: user.name,
    email: user.email,
  };
};
