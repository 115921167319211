import ZAFClient from '../zaf-client';

const setSubect = async (ticket: any = {}): Promise<void> => {
  const subject = ticket?.subject;

  if (!subject) {
    return;
  }

  try {
    await ZAFClient.set('ticket.subject', subject);
  } catch (error) {
    console.error('Error setting subject', error);
  }
};

export default setSubect;
