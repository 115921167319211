import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import {Alert} from '../../Components';
import {Macro} from '../../stores/types';

const AutoApply = ({autoApply}: {autoApply?: Macro}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (autoApply) {
      firebase.analytics().logEvent('auto_apply', {macroId: autoApply.id});
    }
  }, [autoApply]);

  if (!autoApply) {
    return null;
  }

  return <Alert color="blue" title={t('Draft created')} />;
};

export default AutoApply;
