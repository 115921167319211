import React, {useEffect} from 'react';
import firebase from 'firebase/app';

import {Status} from '../stores/app';
import c from '../lib/connectors';

import Content from './Content';
import Error from './Error';
import Loading from './Loading';
import PostInstall from './Post-Install';
import Settings from './Settings';

const Views = ({status}: {status: Status}) => {
  useEffect(() => {
    c().utils.resize();
    firebase.analytics().setCurrentScreen(status);
    firebase.analytics().logEvent('page_view', {page_location: status});
  }, [status]);

  switch (status) {
    case 'IDLE':
      return null;
    case 'LOADING':
      return <Loading />;
    case 'ERROR':
      return <Error />;
    case 'POST_INSTALL':
      return <PostInstall />;
    case 'DISPLAY_CONTENT':
      return <Content />;
    case 'SETTINGS':
      return <Settings />;
    default:
      return null;
  }
};

export default Views;
