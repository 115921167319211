import ZAFClient from '../zaf-client';

const setCommentType = async (ticket: any = {}): Promise<void> => {
  const type = ticket?.comment?.type;

  if (!type) {
    return;
  }

  try {
    await ZAFClient.set('comment.type', type);
  } catch (error) {
    console.error('Error setting comment type', error);
  }
};

export default setCommentType;
