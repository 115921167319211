import ZAFClient from '../zaf-client';

const setTicketFormId = async (ticket: any = {}): Promise<void> => {
  const formId = Number(ticket?.['ticket_form_id']);

  if (!formId) {
    return;
  }

  try {
    await ZAFClient.set('ticket.form.id', formId);
  } catch (error) {
    console.error('Error setting ticket_form_id', error);
  }
};

export default setTicketFormId;
