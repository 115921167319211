import {useEffect, useMemo, useState, useCallback} from 'react';
import Fuse from 'fuse.js';
import firebase from 'firebase/app';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';

import {ContentStore} from '../../../../stores';

const useSearch = () => {
  const {t} = useTranslation();
  const [search, setSearch] = useState('');
  const {macros, macroApplied, suggestedMacroIds} = ContentStore.useState(
    (s) => ({
      suggestedMacroIds: s.data.suggestedMacroIds,
      macroApplied: s.macroApplied,
      macros: s.macros,
    })
  );

  const searchAnalytics = useMemo(
    () =>
      debounce(
        (search: string) =>
          firebase.analytics().logEvent('search', {search_term: search}),
        750
      ),
    []
  );

  const handleChange = useCallback(
    (search: string) => {
      setSearch(search);
      searchAnalytics(search);
    },
    [searchAnalytics]
  );

  const fuse = useMemo(() => new Fuse(macros, {keys: ['title']}), [macros]);

  // reset search if macroappli
  useEffect(() => {
    if (macroApplied) {
      setSearch('');
    }
  }, [macroApplied]);

  const filteredMacros = useMemo(() => fuse.search(search), [fuse, search]);

  return {
    t,
    search,
    handleChange,
    filteredMacros,
    suggestedMacroIds,
  };
};

export default useSearch;
