import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import {Alert} from '../../Components';
import {IContentStore} from '../../stores/content';

const AutoReply = ({
  autoReply,
}: {
  autoReply?: IContentStore['data']['autoReply'];
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (autoReply) {
      firebase.analytics().logEvent('auto_reply', {macroId: autoReply.macroId});
    }
  }, [autoReply]);

  if (!autoReply) {
    return null;
  }

  const replyAt = DateTime.fromISO(autoReply.replyAt);

  return (
    <Alert
      color="green"
      title={t('Automation enabled')}
      description={
        replyAt > DateTime.local()
          ? t('Sending reply in progress...')
          : `${t('An auto-reply will be sent')} ${replyAt.toRelative()}`
      }
    />
  );
};

export default AutoReply;
