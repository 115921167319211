import i18n from '../../../../i18n';
import {AppStore, IAppStore, Status} from '../../../../stores/app';
import {Connector} from '../..';

import {display} from '../requests';
import ZAFClient from '../utils/zaf-client';

export const initContent: Connector['init']['initContent'] = async () => {
  return new Promise((resolve) => {
    ZAFClient.on('app.registered', async () => {
      // * Set token and api endpoint
      const settings: IAppStore['settings'] = await ZAFClient.metadata().then(
        (r: any) => r.settings
      );

      AppStore.update((s) => {
        s.settings.api = settings.api;
        s.settings.debug = settings.debug;
        s.settings.token = 'token' in settings ? settings.token : null;
      });

      try {
        const {messages, data} = await display();

        const macros: {title: string; id: any}[] =
          (await ZAFClient.get('macros'))?.macros ?? [];

        resolve({
          messages,
          data,
          macros: macros.map(({title, id}) => ({title, id: String(id)})),
        });
      } catch (error) {
        handleError(error);
        return resolve(undefined);
      }
    });
  });
};

function handleError(err: any = {}) {
  if ([400, 401, 403].includes(err?.status)) {
    return AppStore.update((s) => {
      s.status = Status.POST_INSTALL;
    });
  }

  return AppStore.update((s) => {
    s.status = Status.ERROR;
    s.alert = {
      description: i18n.t(
        'An error occurred. Please contact us if you need help: help@hubwa.re'
      ),
    };
  });
}
