/* eslint camelcase: 0 */
import {getCustomFields} from '../utils/custom-fields';

import ZAFClient from '../utils/zaf-client';

jest.mock('../zaf-client');

ZAFClient.get = jest.fn().mockReturnValue(Promise.resolve({}));
global.console.error = jest.fn();
beforeEach(() => {
  console.error.mockClear();
  ZAFClient.get.mockClear();
});

describe('custom-fields', () => {
  it('Should return undefined if no ticket', () => {
    expect.assertions(2);
    ZAFClient.get = jest.fn().mockReturnValueOnce(Promise.resolve({}));

    return getCustomFields().then((res) => {
      expect(ZAFClient.get).toHaveBeenCalledWith('ticketFields');
      expect(res).toBeUndefined();
    });
  });

  it('Should return undefined if no custom fields in ticket', () => {
    expect.assertions(2);
    ZAFClient.get = jest
      .fn()
      .mockReturnValueOnce(Promise.resolve({foo: 'bar'}));

    return getCustomFields().then((res) => {
      expect(ZAFClient.get).toHaveBeenCalledTimes(1);
      expect(res).toBeUndefined();
    });
  });

  it('Should return valid customField', () => {
    expect.assertions(3);
    ZAFClient.get = jest
      .fn()
      .mockReturnValueOnce(
        Promise.resolve({
          ticketFields: [
            {
              name: 'custom_field_1',
              label: 'fooLabel',
            },
          ],
        })
      )
      .mockReturnValueOnce({
        'ticket.customField:custom_field_1': 'bar',
      });

    return getCustomFields().then((res) => {
      expect(ZAFClient.get).toHaveBeenCalledTimes(2);
      expect(ZAFClient.get.mock.calls[1]).toEqual([
        'ticket.customField:custom_field_1',
      ]);

      expect(res).toEqual([
        {
          label: 'fooLabel',
          name: 'custom_field_1',
          value: 'bar',
        },
      ]);
    });
  });

  it('Should catch error', () => {
    expect.assertions(1);
    ZAFClient.get = jest
      .fn()
      .mockReturnValueOnce(Promise.reject(new Error('fail')))
      .mockReturnValueOnce({
        'ticket.customField:custom_field_1': 'bar',
      });

    return getCustomFields().then((res) => {
      expect(console.error).toHaveBeenCalledWith(
        '[Silent error] error with customField #2:',
        new Error('fail')
      );
    });
  });

  it('Should catch error 2', () => {
    expect.assertions(1);
    ZAFClient.get = jest
      .fn()
      .mockReturnValueOnce(
        Promise.resolve({
          ticketFields: [
            {
              name: 'custom_field_1',
              label: 'fooLabel',
            },
          ],
        })
      )
      .mockReturnValueOnce(Promise.reject(new Error('fail')));

    return getCustomFields().then((res) => {
      expect(console.error).toHaveBeenCalledWith(
        '[Silent error] error with customField #1:',
        new Error('fail')
      );
    });
  });
});
