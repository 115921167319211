import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/analytics';

import './styles.css';
import './i18n';
import App from './App';

import reportWebVitals from './reportWebVitals';

// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyC6xZbFlF6UdCdpEMCygTAVTVCRyxGppBw',
  projectId: 'hubware-production-firebase',
  appId: '1:867333964202:web:3f027afd65305d45676bd3',
});
// @ts-ignore
window.analytics = firebase.analytics();
// @ts-ignore
window.firebase = firebase;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
