import {useEffect, useMemo, useState} from 'react';
import firebase from 'firebase/app';

import {ContentStore, AppStore} from '../../../stores';
import c from '../../../lib/connectors';
import {legacyAlertHandler} from './utils';

const DISABLED_LOGIC = ['script disabled', 'no macro script'];

const useMessages = () => {
  const {messages, filtersRes} = ContentStore.useState((s) => ({
    messages: s.messages,
    filtersRes: s.data.filtersResult,
  }));
  const defaultTab = AppStore.useState((s) => s.settings.defaultTab);

  const [tab, setTab] = useState(defaultTab);

  useEffect(() => {
    const elements = document
      ?.getElementById('messages-wrapper')
      ?.getElementsByTagName('a');

    Array.from(elements || []).forEach((element) => {
      element.addEventListener('click', () => {
        c().interactions.linkClicked({href: element.href});
        firebase.analytics().logEvent('data_link_click');
      });
    });
  }, [tab]);

  const legacyAlert = useMemo(() => legacyAlertHandler(messages), [messages]);
  const filtersResult = useMemo(
    () => filtersRes?.filter(({reason}) => !DISABLED_LOGIC.includes(reason)),
    [filtersRes]
  );

  const tabs = useMemo(
    () => ({
      logic: !!filtersResult?.length,
      user: !!messages?.[0]?.body,
      data: !!messages?.[1]?.body,
    }),
    [filtersResult, messages]
  );

  useEffect(() => c().utils.resize(), [tab]);

  return {
    messages,
    tabs,
    tab,
    setTab,
    legacyAlert,
    filtersResult,
  };
};

export default useMessages;
