import ZAFClient from './zaf-client';
import {Connector} from '../..';

export const resize: Connector['utils']['resize'] = () => {
  const height = document.getElementById('root')?.clientHeight ?? 200;
  ZAFClient.invoke('resize', {width: '100%', height: `${height}px`});
};

export const getSignUpParams: Connector['utils']['getSignUpParams'] = async () => {
  const ticket = await ZAFClient.get('ticket').then((r: any) => r.ticket);

  return ticket?.brand?.subdomain
    ? `?fromZendesk=${ticket?.brand?.subdomain}`
    : '';
};
