import ZAFClient from './utils/zaf-client';

export const isCommentPublic = async () => {
  const ticket = await ZAFClient.get('ticket').then((r: any) => r.ticket);
  return ticket.comment?.type !== 'internalNote';
};

export const isCommentEmpty = async () => {
  const ticket = await ZAFClient.get('ticket').then((r: any) => r.ticket);
  const text = ticket.comment?.text;
  return text === '<p></p>' || text === '' || !text;
};
