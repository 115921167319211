import React from 'react';

import {Alert, Tabs} from '../../../Components';
import c from '../../../lib/connectors';
import LegacyMessage from './Legacy-Message';
import Logic from './Logic';
import useTabs from './useTabs';

const Messages = () => {
  const {messages, tab, setTab, legacyAlert, tabs, filtersResult} = useTabs();

  if (legacyAlert) {
    c().utils.resize();
    return <Alert {...legacyAlert} />;
  }

  return (
    <>
      <Tabs tabs={tabs} tab={tab} setTab={setTab} />

      {tab === 'logic' && <Logic filtersResult={filtersResult} />}
      <div id="messages-wrapper">
        {tab === 'user' && <LegacyMessage body={messages?.[0]?.body} />}
        {tab === 'data' && <LegacyMessage body={messages?.[1]?.body} />}
      </div>
    </>
  );
};

export default Messages;
