import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import c from '../../../lib/connectors';
import {ContentStore} from '../../../stores';
import {Macro} from '../../../stores/types';
import {parseLogs} from '../../../Components/Logic-logs';

const useMacroButton = ({macro}: {macro: Macro}) => {
  const {t} = useTranslation();
  const [displayLogic, setsDisplayLogic] = useState(false);
  const {macroApplied, filtersResult} = ContentStore.useState((s) => ({
    macroApplied: s.macroApplied,
    filtersResult: s.data?.filtersResult || [],
  }));
  const isApplied = useMemo(() => macroApplied === macro.id, [
    macro.id,
    macroApplied,
  ]);

  const handleClick = useCallback(() => {
    if (isApplied) {
      firebase.analytics().logEvent('cancel_macro', {macroId: macro.id});
      c().interactions.cancelAppliedMacro({macro});
    } else {
      firebase.analytics().logEvent('apply_macro', {macroId: macro.id});
      c().interactions.applySuggestedMacro({macro});
    }
  }, [isApplied, macro]);

  const logs = useMemo(
    () => parseLogs(filtersResult.filter(({id}) => id === macro.id)),
    [filtersResult, macro.id]
  );

  const toggleLogic = useCallback(() => {
    if (logs.length) {
      setsDisplayLogic(!displayLogic);
    }
  }, [logs, setsDisplayLogic, displayLogic]);

  return {
    t,
    logs,
    displayLogic,
    setsDisplayLogic,
    isApplied,
    handleClick,
    toggleLogic,
  };
};

export default useMacroButton;
