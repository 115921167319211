import ZAFClient from '../zaf-client';

import SetAssignee from './set-assignee';
import SetBrand from './set-brand';
import SetCommentType from './set-comment-type';
import SetCustomfield from './set-custom-field';
import ReSetCollaborators from './reset-collaborators';
import SetStatus from './set-status';
import SetPriority from './set-priority';
import SetSubject from './set-subject';
import ReSetTags from './set-tags';
import SetTicketFormId from './set-ticket-form-id';

const libs = {
  SetAssignee,
  SetBrand,
  SetCommentType,
  SetCustomfield,
  ReSetCollaborators,
  SetStatus,
  SetPriority,
  SetSubject,
  ReSetTags,
  SetTicketFormId,
};

const resetTicket = async ({ticket}: {ticket: any}): Promise<void> => {
  await Promise.all([
    ZAFClient.set('comment.text', ''),
    libs.SetCommentType(ticket),
    libs.SetAssignee(ticket),
    libs.SetBrand(ticket),
    libs.SetCustomfield(ticket),
    libs.ReSetCollaborators(ticket),
    libs.SetPriority(ticket),
    libs.SetStatus(ticket),
    libs.SetSubject(ticket),
    libs.ReSetTags(ticket),
    libs.SetTicketFormId(ticket),
  ]);
};

export default resetTicket;
