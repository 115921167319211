import {AppStore} from '../../../../stores';
import ZAFClient from '../utils/zaf-client';

export const rq = async ({
  path,
  data,
}: {
  path: string;
  data: any;
}): Promise<any> => {
  const appStore = AppStore.getRawState();
  // overrides api.hubwa.re with new urlt
  const baseURI =
    appStore?.settings?.api === 'https://api.hubwa.re'
      ? 'https://api2.hubwa.re'
      : appStore?.settings?.api;
  console.log(
    appStore?.settings?.api === 'https://api.hubwa.re',
    Math.random() <= 0.3
  );
  const ticket = await ZAFClient.get('ticket').then((r: any) => r.ticket);

  if (window.location.hostname !== 'localhost') {
    // Production - use secure
    const opts = {
      url: baseURI + path,
      type: 'POST',
      secure: true,
      contentType: 'application/json',
      headers: {
        'x-hubware-authentication': '{{setting.token}}',
        'Content-Type': 'application/json',
        referrer: ticket?.brand?.url,
      },
      data: JSON.stringify(data),
    };
    return ZAFClient.request(opts);
  }

  const opts = {
    method: 'POST',
    headers: {
      'x-hubware-authentication': appStore.settings?.token ?? '',
      'Content-Type': 'application/json',
      referrer: ticket?.brand?.url,
    },
    body: JSON.stringify(data),
  };
  return fetch(baseURI + path, opts).then(async (r) => {
    if (r.status === 200) {
      try {
        const res = await r.json();
        return res;
      } catch (error) {
        console.error(error);
        return;
      }
    } else if (r.status >= 400) {
      const err = new Error('Http error ' + r.status);
      // @ts-ignore
      err.status = r.status;
      throw err;
    }
  });
};

export const phoneSupport = ({
  ticket,
}: {
  ticket: {[key: string]: any};
}): {[key: string]: any} => {
  const res = {...ticket};
  const channel: string = ticket?.via?.channel ?? '';

  if (
    ['voice_inbound', 'voice_outbound'].includes(channel) &&
    !ticket?.via?.source?.from?.phone
  ) {
    const identity: any = (ticket?.requester?.identities ?? []).find(
      (i: any) => i.type === 'phone_number'
    );

    if (identity?.value) {
      const from = {
        formatted_phone: identity.value /* eslint camelcase: 0 */,
        name: 'Hubware reformated phone',
        phone: identity.value,
      };
      res.via.source.from = from;
    }
  }

  return res;
};
