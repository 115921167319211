import {AppStore} from '../stores/app';

export const setSetting = ({key, value}: {key: string; value: string}) => {
  AppStore.update((s) => {
    // @ts-ignore
    s.settings[key] = value;
  });
  localStorage.setItem(key, value);
};

export const initSetting = (key: string) => {
  const value = localStorage.getItem(key);

  if (value !== null) {
    AppStore.update((s) => {
      // @ts-ignore
      s.settings[key] = value;
    });
  }
};
