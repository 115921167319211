import ZAFClient from '../zaf-client';

const setAssignee = async (ticket: any = {}): Promise<void> => {
  const {assignee_id, group_id} = ticket;

  // Don't test !assignee_id or group_id because they could be null
  if (!assignee_id && !group_id) {
    return;
  }

  try {
    await ZAFClient.set('ticket.assignee', {
      userId: assignee_id,
      groupId: group_id,
    });
  } catch (error) {
    console.error('Error setting assignee', error);
  }
};
export default setAssignee;
