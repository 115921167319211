import React from 'react';
import {Bell, Check, Zap} from 'react-feather';

import {Macro} from '../../../stores/types';
import {Button, LogicLogs} from '../../../Components';
import {getTitle} from './utils';
import useMacroButton from './useMacroButton';

const MacroButton = ({
  macro,
  suggested = false,
}: {
  macro: Macro;
  suggested?: boolean;
}) => {
  const {
    displayLogic,
    isApplied,
    handleClick,
    toggleLogic,
    logs,
    t,
  } = useMacroButton({
    macro,
  });

  return (
    <>
      <div className="flex items-center justify-between">
        <div
          className={`${
            logs.length ? 'cursor-pointer' : ''
          }  flex items-center`}
          onClick={toggleLogic}
        >
          {!!logs.length && <Bell size={12} className="text-blue-500 mr-1" />}

          <div className=" text-gray-700">{getTitle(macro.title)}</div>
        </div>
        <Button
          key={macro.id}
          onClick={handleClick}
          Icon={isApplied ? Check : undefined}
          color={isApplied ? 'green' : 'white'}
          size="sm"
        >
          {!isApplied && suggested && (
            <Zap size={15} className="text-yellow-500 mr-1" />
          )}
          {isApplied ? t('Cancel') : t('Apply')}
        </Button>
      </div>

      {displayLogic && <LogicLogs logs={logs} />}
    </>
  );
};

export default MacroButton;
