import {ContentStore} from '../../../../stores';
import c from '../..';

import ZAFClient from './zaf-client';

const replaceVariables = async () => {
  const {'comment.text': text}: {'comment.text': string} = await ZAFClient.get(
    'comment.text'
  );
  if (!text || ['', '<p></p>'].includes(text)) {
    return;
  }

  const content = replaceMetadata({text});
  try {
    await ZAFClient.set('comment.text', content);
  } catch (error) {
    console.error('Error inserting comment text', error);
  }
};

export default replaceVariables;

function replaceMetadata({text}: {text: string}) {
  const contentStore = ContentStore.getRawState();
  // remove null or undefined values
  const metaData = contentStore.data?.metaData ?? {};

  let res = `${text}`;
  let replacedVariables = 0;
  // Legacy regexp parser - to delete
  for (const key in metaData) {
    if (key && metaData[key]) {
      const re = new RegExp(`(__${key}__)|(@@${key}@@)`, 'g');
      replacedVariables += (res.match(re) || [])?.length;
      res = res.replace(re, metaData[key]);
    }
  }
  if (replacedVariables > 0) {
    c().requests.analytics({
      eventId: 'VARIABLE_SUBSTITUTION_MACRO_COUNT',
      eventValue: String(replacedVariables),
    });
  }

  return res;
}
