import React from 'react';
import {CheckCircle, AlertTriangle} from 'react-feather';

import {Filter} from '../stores/content';
import {RoundedIcon} from '.';

export const LogicLogs = ({logs}: {logs: string[]}) => (
  <div className="text-sm space-y-2">
    {logs.map((label, index) => (
      <Log key={index} label={label} />
    ))}
  </div>
);

const Log = ({label}: {label: string}) => {
  const success = (l: string) => l[0] === '✓';

  return (
    <div className="flex items-start space-x-2">
      <RoundedIcon
        color={success(label) ? 'green' : 'red'}
        Icon={success(label) ? CheckCircle : AlertTriangle}
        className="h-6 w-6 p-1"
      />
      <div className={success(label) ? 'text-green-700' : 'text-red-700'}>
        {label.substr(2)}
      </div>
    </div>
  );
};

export const parseLogs = (filtersResult: Filter[] = []): string[] => {
  const res = filtersResult
    .map(({log}) => log.filter((l) => ['✗ ', '✓ '].includes(l.substr(0, 2))))
    .reduce((acc, v) => [...acc, ...v], []);

  return [...new Set(res)].sort();
};
