import React from 'react';
import {useTranslation} from 'react-i18next';

import {Alert} from '../Components';
import {AppStore} from '../stores';

const Error = () => {
  const {t} = useTranslation();
  const alertMsg = AppStore.useState((s) => s.alert?.description);

  return (
    <Alert
      color="red"
      title={t('Error')}
      description={alertMsg || t('An error occurred')}
    />
  );
};

export default Error;
