import React from 'react';
import {
  CheckCircle,
  AlertTriangle,
  AlertOctagon,
  PlayCircle,
} from 'react-feather';

import {RoundedIcon} from './index';

export const Alert = ({
  color,
  title,
  description,
  children,
}: {
  color: 'green' | 'red' | 'yellow' | 'blue';
  title: string;
  description?: string;
  children?: React.ReactNode;
}) => {
  const Icon = {
    green: CheckCircle,
    red: AlertTriangle,
    yellow: AlertOctagon,
    blue: PlayCircle,
  };

  return (
    <div className="flex items-center justify-center flex-col h-full py-4">
      <div className="flex">
        <RoundedIcon
          Icon={Icon[color]}
          color={color}
          className="h-10 w-10 p-2"
        />
        <div className="ml-2">
          <h3 className="text-lg leading-6 my-2 font-medium text-gray-900">
            {title}
          </h3>
          {description && (
            <p className="text-sm leading-5 text-gray-600 whitespace-pre-line">
              {description}
            </p>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
