import React from 'react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Loader} from './loader.svg';

const Loading = () => {
  const {t} = useTranslation();

  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <h1 className="text-lg leading-6 text-gray-500">{t('Loading')}</h1>
      <Loader className="h-8 w-8 text-green-400 fill-current" />
    </div>
  );
};

export default Loading;
