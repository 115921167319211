import i18n from '../../../i18n';
import c, {Connector} from '..';
import {applyMacro, cancelMacro} from './utils/macros';
import ZAFClient from './utils/zaf-client';

export const applySuggestedMacro: Connector['interactions']['applySuggestedMacro'] = async ({
  macro,
}) => {
  await applyMacro({macro, emptyComment: false});
  await c().requests.analytics({
    eventId: 'MACRO_APPLIED',
    eventValue: macro.id,
  });
  await notify(i18n.t('Macro applied'));
};

export const applyPreparedMacro: Connector['interactions']['applyPreparedMacro'] = async ({
  macro,
}) => {
  await applyMacro({macro, emptyComment: true});
  await c().requests.analytics({
    eventId: 'MACRO_AUTO_APPLIED',
    eventValue: macro.id,
  });
  await notify(i18n.t('Automation: macro applied'));
};

export const cancelAppliedMacro: Connector['interactions']['cancelAppliedMacro'] = async ({
  macro,
}) => {
  await cancelMacro({macro});
  await c().requests.analytics({
    eventId: 'MACRO_CANCELED',
    eventValue: macro.id,
  });
  await notify(i18n.t('Macro canceled'));
};

export const linkClicked: Connector['interactions']['linkClicked'] = async ({
  href,
}: {
  href: string;
}) => {
  await c().requests.analytics({
    eventId: 'LINK_CLICKED',
    eventValue: href,
  });
};

function notify(text: string): Promise<void> {
  return ZAFClient.invoke('notify', text, 'notice', 2000);
}
