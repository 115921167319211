import {useMemo} from 'react';
import {groupBy} from 'lodash';
import {useTranslation} from 'react-i18next';

import {ContentStore} from '../../../stores';
import {getCategory} from './utils';

const useMacros = () => {
  const {t} = useTranslation();
  const {suggestedIds, macros, macroApplied} = ContentStore.useState((s) => ({
    suggestedIds: s.data.suggestedMacroIds ?? [],
    macroApplied: s.macroApplied,
    macros: s.macros,
  }));

  const macrosByCategory = useMemo(() => {
    const macrosIds = macroApplied
      ? // @ts-ignore
        [...new Set([...suggestedIds, macroApplied])]
      : suggestedIds;

    const suggestedMacros = macros.filter(({id}) => macrosIds.includes(id));
    return groupBy(suggestedMacros, ({title}) => getCategory(title));
  }, [suggestedIds, macros, macroApplied]);

  return {t, macrosByCategory};
};

export default useMacros;
