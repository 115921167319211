import React from 'react';
import cs from 'classnames';

const colors = {
  green: {text: 'text-green-600', bg: 'bg-green-100'},
  red: {text: 'text-red-600', bg: 'bg-red-100'},
  yellow: {text: 'text-yellow-500', bg: 'bg-yellow-100'},
  grey: {text: 'text-grey-600', bg: 'bg-grey-100'},
  blue: {text: 'text-blue-500', bg: 'bg-blue-100'},
};

export const RoundedIcon = ({
  Icon,
  color = 'grey',
  size = 'base',
  className = '',
}: {
  Icon: React.ElementType;
  color?: 'green' | 'red' | 'yellow' | 'grey' | 'blue';
  size?: 'base' | 'sm';
  className?: string;
}) => (
  <div
    className={cs(
      `flex-shrink-0 flex items-center justify-center ${colors[color].bg} rounded-full`,
      className
    )}
  >
    <Icon className={colors[color].text} />
  </div>
);
