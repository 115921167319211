import ZAFClient from '../zaf-client';

const setTags = async (ticket: any = {}): Promise<void> => {
  const tags = ticket?.tags;

  if (!tags) {
    return;
  }

  try {
    await ZAFClient.set('ticket.tags', tags);
  } catch (error) {
    console.error('Error setting tags', error);
  }
};

export default setTags;
