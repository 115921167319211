import {ContentStore} from '../../../../stores';
import {Macro} from '../../../../stores/types';

import resetTicket from './reset-ticket';
import ZAFClient from './zaf-client';
import replaceVariables from './replace-variables';

export const applyMacro = async ({
  macro,
  emptyComment,
}: {
  macro: Macro;
  emptyComment: boolean;
}): Promise<void> => {
  if (emptyComment) {
    await ZAFClient.set('comment.text', '');
  }

  // apply macro
  await ZAFClient.invoke('macro', macro.id);
  // replace metadata in text editor
  await replaceVariables();

  ContentStore.update((s) => {
    s.macroApplied = macro.id;
  });
};

export const cancelMacro = async ({macro}: {macro: Macro}): Promise<void> => {
  const {'ticket.id': ticketId} = await ZAFClient.get('ticket.id');
  const {ticket} = await ZAFClient.request(`/api/v2/tickets/${ticketId}.json`);
  await resetTicket({ticket});
  ContentStore.update((s) => {
    s.macroApplied = undefined;
  });
};
