import {Macro, Analytic} from '../../stores/types';
import {IContentStore} from '../../stores/content';
import {AppStore} from '../../stores';
import {OperatorAction} from '../operatorAction';
import {UserProperties} from '../analytics';

export interface Connector {
  requests: {
    display: () => Promise<{messages: any; data: any}>;
    feedback: () => Promise<Response | void>;
    analytics: (props: Analytic) => Promise<Response | void>;
    operatorAction: (props: {
      action: OperatorAction;
    }) => Promise<Response | void>;
  };
  interactions: {
    applySuggestedMacro: (props: {macro: Macro}) => Promise<void>;
    applyPreparedMacro: (props: {macro: Macro}) => Promise<void>;
    cancelAppliedMacro: (props: {macro: Macro}) => Promise<void>;
    linkClicked: (props: {href: string}) => Promise<void>;
  };
  ticket: {
    isCommentPublic: () => Promise<boolean>;
    isCommentEmpty: () => Promise<boolean>;
  };
  utils: {
    resize: () => void;
    getSignUpParams: () => Promise<string>;
  };
  init: {
    initContent: () => Promise<IContentStore | undefined>;
    onSave: (x: () => Promise<void>) => void;
  };
  analytics: {
    getUserProperties: () => Promise<UserProperties>;
  };
}

export const getConnector = (): Connector => {
  const appStore = AppStore.getRawState();

  const connector = require(`./${appStore.connector}`).default;
  return connector;
};

export default getConnector;
