import React from 'react';
import {CheckCircle, AlertTriangle} from 'react-feather';

import {Button, RoundedIcon} from '../../../Components';
import useIntent from './useIntent';

const Intent = () => {
  const {
    t,
    intent,
    status: {success, error, loading},
    handleClick,
  } = useIntent();

  if (!intent) {
    return null;
  }
  const sent = success || error;

  return (
    <div>
      <hr className="my-4" />
      <div className="flex text-gray-700">
        <h5 className="w-1/4 font-medium">{t('Intent')}</h5>
        <div className="w-3/4 text-right">
          <div className="mb-2 text-sm">{intent}</div>
          {!sent && (
            <Button
              onClick={handleClick}
              loading={loading}
              disabled={success || error}
              size="sm"
            >
              {loading ? t('Loading...') : t('Wrong?')}
            </Button>
          )}
          {sent && (
            <div className="flex items-center justify-end space-x-1">
              <RoundedIcon
                color={success ? 'green' : 'red'}
                Icon={success ? CheckCircle : AlertTriangle}
                className="h-6 w-6 p-1"
              />
              <div className="text-sm">
                {success && t('Thank you')}
                {error && t('An error occurred')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Intent;
