import {AppStore} from '../../../stores';
import {Connector} from '..';
import {display} from './requests';

export const initContent: Connector['init']['initContent'] = async () => {
  AppStore.update((s) => {
    s.settings = {...s.settings, api: 'fooApi', token: 'footoken', debug: true};
  });
  const {messages, data} = await display();

  const macros: {title: string; id: any}[] = require('./data/macros.json');

  return {
    messages,
    data,
    macros: macros.map(({title, id}) => ({title, id: String(id)})),
  };
};

export const onSave: Connector['init']['onSave'] = () => {
  console.info('No hook initialized');
};
