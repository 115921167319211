import React, {useEffect} from 'react';
import styled from 'styled-components';

import {init} from './lib/init';
import {AppStore, Status} from './stores/app';
import Views from './Views';
import Debugger from './Debugger';

const App = () => {
  const {status, connector, debug} = AppStore.useState((s) => ({
    status: s.status,
    connector: s.connector,
    debug: s.settings.debug,
  }));

  useEffect(() => {
    if (status === 'IDLE') {
      AppStore.update((s) => {
        s.status = Status.LOADING;
      });
      init();
    }
  }, [status]);

  return (
    <StyledApp className="h-full" connector={connector}>
      <Views status={status} />
      {debug && <Debugger />}
    </StyledApp>
  );
};

export default App;

const offlineStyle = `
background-color: #f8f9f9;
  padding: 15px;
  width: 370px;
  & > div {
    border: 1px solid #d8dcde;
    background-color: #fff;
    padding: 10px 15px 20px;
  }
`;

const StyledApp = styled.div<{connector: string}>`
  ${(props) => (props.connector === 'offline' ? offlineStyle : '')}
`;
