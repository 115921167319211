import {ContentStore} from '../../../stores';
import c, {Connector} from '..';

export const applySuggestedMacro: Connector['interactions']['applySuggestedMacro'] = async ({
  macro,
}) => {
  ContentStore.update((s) => {
    s.macroApplied = macro.id;
  });
  await c().requests.analytics({
    eventId: 'MACRO_APPLIED',
    eventValue: macro.id,
  });
  console.info('Macro applied:', macro);
};

export const applyPreparedMacro: Connector['interactions']['applyPreparedMacro'] = async ({
  macro,
}) => {
  ContentStore.update((s) => {
    s.macroApplied = macro.id;
  });
  await c().requests.analytics({
    eventId: 'MACRO_AUTO_APPLIED',
    eventValue: macro.id,
  });
  console.info('Macro auto applied:', macro);
};

export const cancelAppliedMacro: Connector['interactions']['cancelAppliedMacro'] = async ({
  macro,
}) => {
  ContentStore.update((s) => {
    s.macroApplied = undefined;
  });
  await c().requests.analytics({
    eventId: 'MACRO_CANCELED',
    eventValue: macro.id,
  });
  console.info('Macro canceled:', macro);
};

export const linkClicked: Connector['interactions']['linkClicked'] = async ({
  href,
}: {
  href: string;
}) => {
  await c().requests.analytics({
    eventId: 'LINK_CLICKED',
    eventValue: href,
  });
  console.info('Link clicked reported', href);
};
