import ZAFClient from '../zaf-client';

const setStatus = async (ticket: any = {}): Promise<void> => {
  const status = ticket?.status;

  if (!status) {
    return;
  }

  try {
    await ZAFClient.set('ticket.status', status);
  } catch (error) {
    console.error('Error setting status', error);
  }
};

export default setStatus;
