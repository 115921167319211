import {useMemo, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import c from '../../../lib/connectors';
import {ContentStore} from '../../../stores';

type Status = {loading?: boolean; success?: boolean; error?: boolean};

const useIntent = () => {
  const {t} = useTranslation();
  const [status, setStatus] = useState<Status>({});

  const {intent} = ContentStore.useState((s) => ({
    intent: s.data.intent,
  }));

  const handleClick = useCallback(async () => {
    setStatus({loading: true});
    firebase.analytics().logEvent('wrong_intent', {intent: intent?.raw});
    try {
      await c().requests.feedback();
      setStatus({success: true});
    } catch (error) {
      setStatus({error: true});
    }
  }, [setStatus, intent?.raw]);

  const intentTitle = useMemo(
    (): string => intent?.locale || intent?.raw || '',
    [intent]
  );

  return {intent: intentTitle, status, handleClick, t};
};

export default useIntent;
