import {DateTime} from 'luxon';

import {ContentStore, IContentStore} from '../../stores/content';

export interface Input {
  macroApplied?: boolean;
  suggestedMacrosEmpty?: boolean;
  scheduledAutoReplied?: boolean;
  suggestedMacrosToBeAutoApplied?: boolean;
}

export const isAppEnabled = (): boolean => {
  const {data} = ContentStore.getRawState();

  return (
    Boolean(data.loggerFlags?.applicationEnabled) &&
    Boolean(data.loggerFlags?.macroSupportEnabled)
  );
};

export const getInputs = (): Input => {
  const content = ContentStore.getRawState();

  return {
    macroApplied: isMacroApplied(content),
    suggestedMacrosEmpty: !(content.data?.suggestedMacroIds ?? []).length,
    scheduledAutoReplied: hasAutoReplied(content.data.autoReply?.replyAt),
    suggestedMacrosToBeAutoApplied: hasAutoApply(content),
  };
};

const isMacroApplied = (content: IContentStore) =>
  Boolean(
    content.macroApplied &&
      content.data.suggestedMacroIds?.includes(content.macroApplied)
  );

const hasAutoReplied = (replyAt: string = '') =>
  DateTime.fromISO(replyAt).isValid &&
  DateTime.fromISO(replyAt) > DateTime.local();

const hasAutoApply = (s: IContentStore) => {
  const suggestedIds = s.data.suggestedMacroIds ?? [];
  const autoApplyIds = s.data.autoApplyMacroIds ?? [];
  const macros = s.macros;

  return (
    suggestedIds.length === 1 &&
    autoApplyIds.includes(suggestedIds[0]) &&
    Boolean(macros.find(({id}) => id === suggestedIds[0]))
  );
};
