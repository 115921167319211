import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import {Alert, Button} from '../Components';
import c from '../lib/connectors';

const POstInstall = () => {
  const {t} = useTranslation();
  const [params, setparams] = useState('');

  useEffect(() => {
    c()
      .utils.getSignUpParams()
      .then((params) => setparams(params));
  }, []);

  return (
    <Alert
      color="green"
      title={t('Almost there!')}
      description={t(
        'Click the button below in order to activate the application'
      )}
    >
      <Button
        color="green"
        className="mt-6"
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://app.hubwa.re/sign-up${params}`}
        onClick={() => firebase.analytics().logEvent('sign_up', {})}
      >
        {t('Activate')}
      </Button>
    </Alert>
  );
};

export default POstInstall;
