import React from 'react';
import styled from 'styled-components';
import cs from 'classnames';

const colors = {
  white: 'bg-white hover:bg-gray-100 text-gray-800 border-gray-300',
  red: 'bg-red-500 hover:bg-red-600 text-white border-red-600',
  green: 'bg-green-500 hover:bg-green-600 text-white border-green-600',
};

const sizes = {
  sm: 'text-xs py-1 px-2',
  md: 'text-sm py-1 px-4',
  lg: 'text-base py-2 px-4',
};

export const Button = ({
  children,
  Icon,
  className = '',
  disabled = false,
  loading = false,
  color = 'white',
  size = 'md',
  as: El = 'button',
  ...props
}: {
  children?: React.ReactNode;
  Icon?: React.ElementType;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  color?: 'white' | 'red' | 'green';
  size?: 'sm' | 'md' | 'lg';
  as?: React.ElementType;
  [key: string]: any;
}) => (
  <El
    className={cs(
      className,
      colors[color],
      sizes[size],
      (disabled || loading) && 'opacity-50 cursor-not-allowed',
      'font-medium border rounded inline-flex items-center focus:outline-none transition ease-in-out duration-150'
    )}
    disabled={disabled}
    {...props}
  >
    {Icon && !loading && <Icon className="w-4 h-4 mr-2" />}
    {loading && <Spinner />}
    {children}
  </El>
);

const Spinner = styled.div.attrs({
  className:
    'loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-2',
})`
  border-top-color: #4299e1;
  animation: spinner 0.4s linear infinite;

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
