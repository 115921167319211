import {DateTime} from 'luxon';
import React from 'react';
import styled from 'styled-components';

const LegacyMessage = ({body}: {body?: string}) => {
  if (!body) {
    return null;
  }

  return (
    <StyledLegacy dangerouslySetInnerHTML={{__html: parseLegacyDates(body)}} />
  );
};

export default LegacyMessage;

const parseLegacyDates = (body: string): string =>
  body.replace(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
    (d) => {
      const date = DateTime.fromISO(d);
      return date.isValid ? date.toLocaleString() : d;
    }
  );

const StyledLegacy = styled.div.attrs({
  className: 'text-gray-700',
})`
  a {
    color: #3182ce;
    font-weight: 400;
  }
  b, strong {
    font-weight: 600;
  }

  .pull-right {
    float: right;
  }

  .panel-heading {
    background-color: #f7fafc;
    font-weight: 500;
  }

  .label, .badge {
    line-height: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #faf089;
    border-radius: 9999px;
    font-weight: 400;
  }

  .badge {
    color: #2f855a!important;
    background-color: #f0fff4!important;
  }

  table {
    margin-top: 0.5rem;
  }

  tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  th {
    text-align: left!important;
    font-weight: 600;

    br {
      display: none;
    }
  }

  td {
    padding-left: 0.5rem;
    padding-bottom: .5rem;

    > br {
      content: "";
      margin: 1em;
      display: block;
      font-size: 24%;
    }

    b, strong {
      font-weight: 500;
    }

    .container {
      padding: 0!important;
      .row {
        margin-bottom: 0.5rem;
      }
    }
  }

  .small {
    font-size: 0.875rem;
  }
}`;
