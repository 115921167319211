import queryString from 'query-string';

import {Connector} from '..';

export const display: Connector['requests']['display'] = async () => {
  await sleep(300);

  const {offlineData = 'display'} = queryString.parse(window.location.search);

  return require(`./data/${offlineData}.json`);
};

export const feedback: Connector['requests']['feedback'] = async () => {
  await sleep();
  console.info(`Feeback request`);
};

// fake delay for requests
function sleep(delay = 1000) {
  return new Promise((r) => setTimeout(r, 1000));
}

export const analytics: Connector['requests']['analytics'] = async ({
  eventValue,
  eventId,
}) => {
  console.info(`Analytic request`, eventId, eventValue);
};

export const operatorAction: Connector['requests']['operatorAction'] = async ({
  action,
}) => {
  console.info('Operator action sent to backend:', operatorAction);
};
