import React from 'react';
import styled from 'styled-components';

import MacroButton from '../Macro-Button';
import useSearch from './useSearch';

const Macros = () => {
  const {
    t,
    search,
    handleChange,
    filteredMacros,
    suggestedMacroIds,
  } = useSearch();

  return (
    <div className="px-1 w-full">
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 my-2 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="search"
        value={search}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={t('Search macro')}
      />
      {!!filteredMacros.length && (
        <StyledResultsWrapper>
          {filteredMacros.slice(0, 20).map(({item: macro}: any) => (
            <MacroButton
              macro={macro}
              key={macro.id}
              suggested={suggestedMacroIds?.includes(macro.id)}
            />
          ))}
        </StyledResultsWrapper>
      )}
    </div>
  );
};

export default Macros;

const StyledResultsWrapper = styled.div.attrs({
  className: 'bg-white shadow p-2 mb-2 space-y-1 text-sm overflow-auto',
})`
  max-height: 144px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;
