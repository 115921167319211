import ZAFClient from './zaf-client';

interface customField {
  value?: string;
  label: any;
  name: any;
}
interface TicketCustomField {
  [key: string]: string;
}

export const getCustomFields = async (): Promise<customField[]> => {
  let ticketFields;
  try {
    ticketFields = await ZAFClient.get('ticketFields').then(
      (r: any) => r.ticketFields
    );
  } catch (err) {
    console.error('[Silent error] error with customField #2:', err);
  }

  if (!Array.isArray(ticketFields)) {
    return [];
  }

  // Filter ticketFields to keep only custom fields
  const hubwareCustomFields = ticketFields
    .filter(({name = ''}) => name.startsWith('custom_field_'))
    .map(({label, name}) => ({label, name}));

  // Fetch all cutomfields and add value to hubwareCustomFields object
  const ticketCustomFields: TicketCustomField[] = await Promise.all(
    hubwareCustomFields.map((field) =>
      ZAFClient.get(`ticket.customField:${field.name}`)
    )
  ).catch((err) => {
    console.error('[Silent error] error with customField #1:', err);
    return [];
  });
  // Example of values returned from zendesk:
  // [
  //   {
  //     'ticket.customField:custom_field_12244': 'foo',
  //   },
  //   {
  //     'ticket.customField:custom_field_567889': 'bar',
  //   },
  // ];

  return hubwareCustomFields.map((field) => {
    const key = `ticket.customField:${field.name}`;
    const matchedField = ticketCustomFields.find((t) => t[key]);

    return {...field, value: matchedField?.[key] ?? undefined};
  });
};
