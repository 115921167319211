import c from '../connectors';
import {isAppEnabled, getInputs, Input} from './utils';

export type OperatorAction =
  | 'NO_SUGGESTIONS_MADE' // Pas de suggestions
  | 'AUTO_REPLY_IGNORED' // Suggestion automatique non utilisée
  | 'AUTO_APPLY_USED' // Suggestion complète utilisée
  | 'AUTO_APPLY_CANCELED' // Suggestion complète non utilisée
  | 'SUGGESTED_MACRO_CLICKED' // Suggestion partielle utilisée
  | 'SUGGESTED_BUT_NOT_CLICKED'; // Suggestion partielle non utilisée

export const getOperatorAction = async (): Promise<
  OperatorAction | undefined
> => {
  const [enabled, isPublic, isEmpty] = await Promise.all([
    isAppEnabled(),
    c().ticket.isCommentPublic(),
    c().ticket.isCommentEmpty(),
  ]);

  if (!enabled || !isPublic || isEmpty) {
    return;
  }

  const input = getInputs();

  const operatorAction = TABLE.find((analytic) =>
    Object.keys(analytic.in).every(
      // @ts-ignore
      (key) => key in input && input[key] === analytic.in[key]
    )
  );

  return operatorAction?.out;
};

const TABLE: {
  in: Input;
  out: OperatorAction;
}[] = [
  {
    in: {
      suggestedMacrosEmpty: true,
    },
    out: 'NO_SUGGESTIONS_MADE', // Pas de suggestions
  },
  {
    in: {
      suggestedMacrosEmpty: false,
      scheduledAutoReplied: true,
    },
    out: 'AUTO_REPLY_IGNORED', // Suggestion automatique non utilisée
  },
  {
    in: {
      suggestedMacrosEmpty: false,
      scheduledAutoReplied: false,
      suggestedMacrosToBeAutoApplied: true,
      macroApplied: true,
    },
    out: 'AUTO_APPLY_USED', // Suggestion complète utilisée
  },
  {
    in: {
      suggestedMacrosEmpty: false,
      scheduledAutoReplied: false,
      suggestedMacrosToBeAutoApplied: true,
      macroApplied: false,
    },
    out: 'AUTO_APPLY_CANCELED', // Suggestion complète non utilisée
  },
  {
    in: {
      suggestedMacrosEmpty: false,
      scheduledAutoReplied: false,
      suggestedMacrosToBeAutoApplied: false,
      macroApplied: true,
    },
    out: 'SUGGESTED_MACRO_CLICKED', // Suggestion partielle utilisée
  },
  {
    in: {
      suggestedMacrosEmpty: false,
      scheduledAutoReplied: false,
      suggestedMacrosToBeAutoApplied: false,
      macroApplied: false,
    },
    out: 'SUGGESTED_BUT_NOT_CLICKED', // Suggestion partielle non utilisée
  },
];
