import ZAFClient from '../zaf-client';

const setCustomField = async (ticket: any = {}): Promise<void> => {
  const customFields = ticket?.custom_fields ?? [];

  if (!customFields) {
    return;
  }

  await Promise.all(
    customFields.map(async (customfield: any) => {
      try {
        await ZAFClient.set(
          `ticket.customField:custom_field_${customfield.id}`,
          customfield.value
        );
      } catch (error) {
        console.error('Error setting custom field', error);
      }
    })
  );
};

export default setCustomField;
