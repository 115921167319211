import ZAFClient from '../zaf-client';

const resetCollaborators = async (originalTicket: any = {}) => {
  const {ticket: currentTicket} = await ZAFClient.get('ticket');
  const originalCollaboratorIds = originalTicket?.['collaborator_ids'];
  const currentCollaborators = currentTicket?.collaborators;

  if (
    !Array.isArray(originalCollaboratorIds) ||
    !Array.isArray(currentCollaborators)
  ) {
    return;
  }

  try {
    await Promise.all(
      currentCollaborators.map(({id}) =>
        ZAFClient.invoke('ticket.collaborators.remove', {id})
      )
    );

    await Promise.all(
      originalCollaboratorIds.map((id) =>
        ZAFClient.invoke('ticket.collaborators.add', {id})
      )
    );
  } catch (error) {
    console.error('Error setting collaborators', error);
  }
};

export default resetCollaborators;
