import {Connector} from '..';
import * as init from './init';
import * as ticket from './ticket';
import * as requests from './requests';
import * as analytics from './analytics';
import * as interactions from './interactions';

const connector: Connector = {
  init,
  utils: {
    resize: () => {
      console.info('Resize requested');
    },
    getSignUpParams: async () => '?fromOffline',
  },
  ticket,
  requests,
  analytics,
  interactions,
};

export default connector;
