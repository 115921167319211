import ZAFClient from '../zaf-client';

const setPriority = async (ticket: any = {}): Promise<void> => {
  const priority = ticket?.priority;

  if (!priority) {
    return;
  }

  try {
    await ZAFClient.set('ticket.priority', priority);
  } catch (error) {
    console.error('Error setting priority', error);
  }
};

export default setPriority;
