import React, {useCallback} from 'react';
import {Settings, X} from 'react-feather';

import {AppStore, Status} from '../stores/app';

export const SettingsButton = () => {
  const status = AppStore.useState((s) => s.status);

  const handleClick = useCallback(() => {
    AppStore.update((s) => {
      s.status =
        status === Status.SETTINGS ? Status.DISPLAY_CONTENT : Status.SETTINGS;
    });
  }, [status]);

  return (
    <div className="absolute top-0 right-0">
      <button
        onClick={handleClick}
        className="focus:outline-none transition ease-in-out duration-150 text-gray-500 hover:text-gray-600"
      >
        {status === Status.SETTINGS ? <X size={15} /> : <Settings size={15} />}
      </button>
    </div>
  );
};
