import {Connector} from '../..';

import ZAFClient from '../utils/zaf-client';

export const onSave: Connector['init']['onSave'] = (sendOperatorAction) => {
  ZAFClient.on('ticket.save', async () => {
    // * Send operatorAction to server. if longer than 2seconds, return true
    const wait2s = new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      await Promise.race([sendOperatorAction(), wait2s]);
    } catch (error) {
      console.error('Error sending operator Action:', error);
    }
    return true;
  });
};
