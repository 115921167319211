import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import firebase from 'firebase/app';

import {ContentStore} from '../../stores';
import {Alert} from '../../Components';

const Sabotage = () => {
  const {t} = useTranslation();
  const sabotage = ContentStore.useState((s) => s.data.sabotage);

  useEffect(() => {
    if (sabotage) {
      firebase.analytics().logEvent('sabotage', {macroId: sabotage.macroId});
    }
  }, [sabotage]);

  if (!sabotage) {
    return null;
  }

  return (
    <div className="pt-3">
      <Alert
        color="yellow"
        title={t('The assistant needs you to answer this message.')}
        description={t('Confirm the suggested answer or choose another macro.')}
      ></Alert>
    </div>
  );
};

export default Sabotage;
