import {Store} from 'pullstate';

export enum Status {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  POST_INSTALL = 'POST_INSTALL',
  DISPLAY_CONTENT = 'DISPLAY_CONTENT',
  SETTINGS = 'SETTINGS',
}

export type DefaultTab = 'logic' | 'user' | 'data';

export interface IAppStore {
  status: Status;
  alert?: {
    description: string;
  };
  settings: {
    token?: string | null;
    api: string;
    debug: boolean;
    defaultTab: DefaultTab;
  };
  connector: 'zendesk' | 'offline';
}

export const AppStore = new Store<IAppStore>({
  status: Status.IDLE,
  settings: {
    token: '',
    api: 'https://api2.hubwa.re',
    debug: false,
    defaultTab: 'user',
  },
  connector: 'offline',
});
