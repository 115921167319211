import firebase from 'firebase/app';

export interface UserProperties {
  id: string;
  role: string;
  locale: string;
  name: string;
  email: string;
}

export const initAnalytics = async ({
  id,
  ...props
}: UserProperties): Promise<void> => {
  firebase.analytics().setUserId(id);
  firebase.analytics().setUserProperties({props});
};
